import React from "react"
import LayoutDefault from "../components/layouts/default"

import Products from "../components/parts/body/products"


export default () => (

  <LayoutDefault>
    <Products></Products>
  </LayoutDefault>

)
