import React from "react"

export default ({ children }) => (

  <div class="position-relative">
    <div class="container">
      <div class="row justify-content-lg-between min-height-100vh--lg">

        <div class="col-lg-5 d-flex align-items-center align-self-lg-center min-height-600 min-height-auto--lg">

          <div class="position-relative z-index-2">
            <div class="mb-7">
              <span class="d-none d-sm-block u-icon u-icon-white u-icon--lg text-primary rounded-circle mb-4">
                <span class="fa fa-credit-card u-icon__inner"></span>
              </span>
              <h1 class="display-4 font-size-48--md-down text-white font-weight-bold">Techtunes <span class="text-warning">Products</span></h1>
              <p class="u-text-light">Simple, secure, industry-standard and elite product step-by-step explanation in three key categories, based on over 100 million users globally.</p>
            </div>

            <a class="btn btn-warning u-btn-warning transition-3d-hover" href="/">
              Get a Free Wallet
              <span class="fa fa-arrow-right small ml-2"></span>
            </a>
          </div>

        </div>



      <div class="col-lg-5 align-self-center u-space-3 u-space-0--lg">


            <div class="text-center mb-7">
              <span class="u-icon u-icon-primary--air rounded-circle mb-4">
                <span class="small font-weight-bold u-icon__inner">01.</span>
              </span>
              <h2 class="h4 text-primary">Learn about Bitcoin</h2>
              <p>Bitcoin is changing the way people think about money. Educate yourself about this ground-breaking payment system.</p>
            </div>


      </div>


      </div>
    </div>


  <div class="d-none d-lg-inline-block position-absolute-bottom-0 bottom-50 text-center">
    <a class="js-go-to u-go-to-modern" href="https://techtunes.io"
       data-target="#contentSection"
       data-type="static">
      <span class="fa fa-arrow-down u-go-to-modern__inner"></span>
    </a>
  </div>


  <div class="col-lg-6 u-gradient-half-primary-v1 position-absolute-top-left-0 min-height-600 min-height-100vh--lg"></div>


</div>

)
